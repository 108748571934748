import { FAQ } from "./faq-block.types";

export const FAQData = [
  {
    title: "Apa itu CoLearn?",
    desc: "CoLearn adalah aplikasi belajar online dengan fitur Bimbel Online dan Latihan. CoLearn hadir sebagai aplikasi belajar untuk membantu murid SD, SMP, SMA untuk bisa menguasai mata pelajaran Matematika, Fisika, dan Kimia.<p>Selain itu, ada video pembahasan untuk mengerjakan PR/tugas yang bisa diakses lewat website <a href='https://colearn.id'>colearn.id</a> dan YouTube channel CoLearn.</p>"
  },
  {
    title: "Apa saja fitur di aplikasi CoLearn?",
    desc: `
      CoLearn memiliki 2 fitur di aplikasi, yaitu:
      <ul>
        <li>Bimbel: tempat kamu bisa belajar online Matematika, Fisika, Kimia langsung di kelas sama Guru Juara, Mentor, dan temen-temen lainnya. </li>
        <li>Latihan: Kumpulan atau bank soal Matematika berbasis Kurikulum 2013 dan Kurikulum Merdeka lengkap dengan jawaban dan cara ngerjainnya. </li>
      </ul>
      <p>Sedangkan, di website <a href='https://colearn.id'>colearn.id</a> dan channel YouTube CoLearn ada bantuan video pembahasan untuk mengerjakan PR/tugas.</p>
    `
  },
  {
    title: "Siapa aja yang bisa pakai CoLearn?",
    desc: "Aplikasi belajar online CoLearn tersedia buat murid kelas 5 sampai kelas 12. CoLearn memberikan bantuan belajar online Matematika, Fisika, Kimia untuk murid SD, SMP, dan SMA."
  },
  {
    title: "Apa saja keuntungan belajar di CoLearn?",
    desc: `
      <ul>
        <li>Murid akan belajar bersama guru atau tenaga pengajar terbaik yang sudah melalui tahap seleksi dan pelatihan untuk bisa mengajar secara online. Pelajaran Matematika, Fisika, Kimia yang sulit akan dibawakan dengan metode mengajar yang menyenangkan, sehingga materi lebih mudah masuk ke otak.</li>
        <li>Jika tidak cocok, ada garansi uang kembali 100%.</li>
        <li>Pengajaran di CoLearn berdasarkan Kurikulum 2013 dan Kurikulum Merdeka, sehingga murid akan mempelajari materi sesuai yang diajarkan di sekolah. </li>
        <li>Jadwal bimbel online CoLearn hanya 2 jam per minggu, tidak akan memakan banyak waktu sehingga murid bisa terus menjalankan hobi atau aktivitas lain yang mereka suka. Hal tersebut memungkinkan karena guru di CoLearn sudah melalui proses pelatihan dan dibekali metode pengajaran yang bisa membantu murid menyerap materi secara lebih cepat.</li><li>Belajar online dari rumah sehingga murid tidak perlu menghabiskan tenaga, waktu, dan bisa belajar secara lebih kondusif. Selain itu, juga bisa membantu orang tua menghemat biaya transportasi untuk anak.</li>
      </ul>
    `
  },
  {
    title: "Berapa biaya CoLearn?",
    desc: "Aplikasi belajar online CoLearn bisa digunakan secara gratis. Namun, terdapat biaya sendiri jika murid ingin bergabung di Bimbel online CoLearn, biaya yang perlu dibayarkan adalah mulai 95.000 per bulan."
  },
  {
    title: "Gimana cara download dan daftar CoLearn?",
    desc: `
      <ul>
        <li>Kalau kamu pakai handphone Android, kamu bisa download aplikasi CoLearn melalui Google Play Store.</li>
        <li>Kalau kamu pakai handphone iOS, kamu bisa download aplikasi CoLearn melalui AppStore.</li>
        <li>Setelah download, kamu bisa mendaftar atau membuat akun dengan memasukkan nomor HP yang aktif dan mengisi data langsung di aplikasi.</li>
      </ul>
    `
  },
  {
    title: "Berapa nomor WA CoLearn?",
    desc: "Khusus untuk mendapatkan info promo dan diskon terbaru dari CoLearn, hubungi nomor WhatsApp 0819-1911-3350. Sedangkan untuk pengaduan konsumen, kamu bisa mengirim pesan ke nomor WhatsApp 0811-1900-5655."
  },
  {
    title: "Apa perbedaan belajar di bimbel Matematika CoLearn dengan Kumon?",
    desc: "Baik bimbel CoLearn maupun Kumon, sama-sama mempelajari Matematika. Pembelajaran di CoLearn di lakukan secara online dan dengan pembelajaran berbasis kelompok."
  },
  {
    title: "CoLearn bisa dibuka di mana aja, sih?",
    desc: `
      <ul>
        <li>Fitur Bimbel bisa buka melalui website di laptop atau aplikasi di HP. </li>
        <li>Bantuan video pembahasan Tanya bisa dibuka melalui website <a href='https://colearn.id'>colearn.id</a> atau channel YouTube CoLearn, caranya yaitu dengan mengetik judul soal atau topik pelajaran Matematika, Fisika, Kimia yang kamu cari dan dapatkan video pembahasan. </li>
        <li>Fitur Latihan dapat dibuka melalui aplikasi di HP, kamu bisa menemukan lebih banyak bank soal berbasis Kurikulum 2013 dan Kurikulum Merdeka. Selain itu, kamu juga bisa membuka fitur Latihan di website untuk melihat kumpulan soal berbasis Kurikulum Merdeka. </li>
      </ul>
    `
  },
  {
    title: "Apa yang dimaksud dengan CoLearn?",
    desc: `
      CoLearn adalah aplikasi belajar yang bertujuan untuk mempermudah murid-murid di Indonesia dalam belajar Matematika, Fisika, Kimia. Lewat beragam fitur di aplikasi belajar CoLearn, pelajaran Matematika, Fisika, dan Kimia yang sulit dibuat jadi lebih menyenangkan.
    `
  },
  {
    title: "Siapa pendiri CoLearn?",
    desc: `
      Bimbel online CoLearn didirikan oleh tiga kawan yaitu Abhay Saboo, Marc Irawan, dan Sandeep Devaram. Para pendiri CoLearn berangkat dari misi yang sama yaitu untuk memajukan pendidikan di Indonesia dan membantu murid-murid Indonesia agar bisa menguasai Matematika, Fisika, Kimia, menciptakan pemimpin masa depan Indonesia yang kompetitif dan bisa bersaing secara global.
    `
  },
  {
    title: "Kapan CoLearn berdiri?",
    desc: `
      Awal mulanya, CoLearn berdiri sebagai bimbel offline bernama IQ Smart Center yang pertama kali dibuka di Bintaro, kemudian berekspansi ke BSD, PIK, dan Harapan Indah selama tahun 2019. Misi CoLearn adalah membekali siswa di Indonesia dengan pelajaran dan keterampilan yang diperlukan untuk bersaing di level global.
      Kemudian di tahun 2020, CoLearn terus berinovasi dalam membangun motivasi murid untuk terus belajar dengan menghadirkan aplikasi belajar online CoLearn. Dengan fitur dan tampilan baru, CoLearn siap bantu siswa SMA dan SMP di Indonesia untuk belajar konsep Matematika. Aplikasi CoLearn sudah bisa diunduh di Google Play Store dan Apple App Store.
    `
  },
  {
    title: "Apakah aplikasi CoLearn itu bayar?",
    desc: `
      Aplikasi CoLearn bisa kamu download gratis. Kalau mau gabung Bimbel online CoLearn, biayanya mulai 95.000 per bulan. 
    `
  },
  {
    title: "Apakah aplikasi CoLearn itu aman?",
    desc: `
      Aplikasi CoLearn aman. CoLearn memastikan keamanan data pengguna sesuai dengan peraturan yang berlaku di Indonesia. Informasi lebih lanjut dapat diakses di colearn.id/kebijakan-privasi
    `
  },
  {
    title: "Apa yang dimaksud dengan aplikasi belajar online?",
    desc: `
      Aplikasi belajar online adalah tempat di mana murid bisa belajar dari jarak jauh. Dengan aplikasi belajar CoLearn, kamu bisa belajar dari mana saja, kapan saja, pakai HP atau laptop.
    `
  },
  {
    title: "Kurikulum apa saja yang di-cover oleh CoLearn?",
    desc: `
      Silabus pembelajaran di CoLearn mencakup Kurikulum 2013 dan Kurikulum Merdeka
    `
  },
  {
    title:
      "Bagaimana efektivitas penggunaan aplikasi pembelajaran online di Indonesia?",
    desc: `
      <ul>
        <li>Kegiatan belajar lebih efisien karena dilakukan secara online sehingga anak tidak perlu mengeluarkan tenaga untuk pergi ke bimbel offline setelah seharian belajar di sekolah.</li>
        <li>Menghemat waktu dan biaya, murid tidak perlu takut terlambat masuk kelas karena belajar online bisa dilakukan di rumah</li>
        <li>Murid bisa mempelajari lebih banyak materi secara lebih fokus dan kegiatan belajar online di kelas lebih kondusif</li>
        <li>Tingkat kehadiran murid lebih tinggi dibandingkan dengan jika murid harus berangkat ke bimbel offline</li>
        <li>Adanya fitur menarik didukung oleh teknologi yang membuat suasana belajar online di kelas jadi lebih menyenangkan</li>
      </ul>
    `
  },
  {
    title:
      "Apa saja kendala yang dihadapi saat menggunakan aplikasi pembelajaran secara online?",
    desc: `
      Kendala yang terkadang dihadapi murid ketika belajar online bisa beragam, mulai dari
      <ul>
        <li>koneksi internet tidak stabil sehingga mengganggu kegiatan belajar online murid</li>
        <li>terbatasnya akses murid ke perangkat laptop atau smartphone</li>
        <li>gangguan suara di rumah yang bisa membuat suasana belajar online kurang kondusif</li>
      </ul>
    `
  },
  {
    title: "Apa yang dimaksud dengan belajar online?",
    desc: `
      Belajar online adalah proses belajar di mana kamu bisa mengikuti kegiatan belajar mengajar melalui aplikasi di HP atau laptop. Kamu nggak perlu pergi ke luar rumah, kegiatan belajar bisa dilakukan di rumah.
    `
  },
  {
    title: "Bagaimana langkah-langkah penerapan belajar online?",
    desc: `
      Kegiatan belajar online berlangsung lewat aplikasi. Di Bimbel CoLearn, penerapan belajar online dilakukan melalui aplikasi CoLearn dan Zoom. Di dalam kelas ada 2 orang guru. Guru Juara memberi penjelasan materi secara langsung, bukan melalui rekaman. Mentor menjawab pertanyaan kamu langsung di kelas. Kamu juga bisa chat dan berinteraksi langsung sama temen sekelas lain.
    `
  },
  {
    title: "Apakah yang dimaksud dengan aplikasi bimbingan belajar online?",
    desc: `
      Aplikasi bimbingan belajar online adalah tempat di mana murid bisa belajar dari jarak jauh. Dengan aplikasi belajar CoLearn, kamu bisa belajar dari mana saja, kapan saja, pakai HP atau laptop.
    `
  },
  {
    title: "Apa saja aplikasi belajar online yang gratis?",
    desc: `
      Aplikasi CoLearn bisa kamu download gratis. Kalau mau gabung Bimbel online CoLearn, biayanya mulai dari 95.000 per bulan. 
    `
  },
  {
    title: "Apa yang dimaksud dengan bimbel?",
    desc: `
      Bimbel adalah bimbingan belajar tempat di mana kamu bisa mendapat bantuan belajar tambahan di luar sekolah.
    `
  },
  {
    title: "Apa perbedaan les dan bimbel?",
    desc: `
      Bimbel biasanya memiliki lebih banyak murid di satu kelas dibanding les. Meskipun jumlah murid lebih banyak, belajar di Bimbel online CoLearn tetap efektif karena ada 2 guru yang siap memastikan kamu bisa mengikuti dan memahami materi dengan mudah. Suasana kelas juga lebih seru dengan adanya teman sekelas.
    `
  },
  {
    title: "Apa tujuan dari bimbingan belajar?",
    desc: `
      Tujuan dari bimbingan belajar adalah untuk memperkuat pemahaman materi yang dipelajari di sekolah, jadi kamu nggak kesulitan lagi mengikuti penjelasan guru, mengerjakan tugas, dan soal-soal ujian di sekolah.
    `
  },
  {
    title: "Bagaimana kalau lupa kata sandi (password) akun CoLearn",
    desc: `
      Untuk masuk/log in ke aplikasi CoLearn kamu nggak perlu password. Cukup masukkan nomor HP, lalu kamu akan mendapat kode verifikasi untuk masuk.
    `
  },
  {
    title: "Apakah siswa SMK bisa menggunakan aplikasi belajar online CoLearn?",
    desc: `
      Bisa. Soal dan kurikulum CoLearn juga cocok untuk murid SMK.
    `
  },
  {
    title: "Apakah aplikasi bimbel CoLearn menyediakan layanan bimbel offline?",
    desc: `
      CoLearn tidak menyediakan bimbel offline. CoLearn memastikan kegiatan belajar mengajar yang efektif juga bisa dilakukan di rumah, sehingga murid dan orang tua bisa menghemat waktu dan biaya.
    `
  },
  {
    title:
      "Apakah aplikasi belajar online CoLearn tersedia untuk semua jenjang? ",
    desc: `
      CoLearn memberikan bantuan belajar online untuk murid kelas 5 SD - 12 SMA. Tapi jangan khawatir, CoLearn akan berusaha untuk menjadi aplikasi belajar yang bisa digunakan semua kalangan dari kelas berapa aja.
    `
  },
  {
    title: "Apakah CoLearn bisa dibuka di laptop dan handphone?",
    desc: `
      Bimbel online CoLearn bisa diakses di laptop dan handphone. Caranya,
      <ul>
        <li>Jika menggunakan laptop, kamu cuma perlu masuk/log in di website CoLearn. Hal ini bisa kamu buka melalui browser di laptop, komputer atau PC, handphone, atau tablet. </li>
        <li>Jika menggunakan handphone Android atau iOS, kamu bisa langsung men-download aplikasi belajar online CoLearn melalui Google Play Store (Android) atau AppStore (iOS). </li>
      </ul>
    `
  },
  {
    title: "Apakah CoLearn gratis? Berapa biaya buat ikut Bimbel CoLearn?",
    desc: `
      Aplikasi CoLearn bisa kamu download gratis. Kalau mau gabung Bimbel online CoLearn, biayanya mulai 95.000 per bulan. 
    `
  },
  {
    title: "Apakah bimbel online di CoLearn bisa dicicil?",
    desc: `
    CoLearn menyediakan opsi pembayaran bulanan paket Bimbel mulai dari 95.000 per bulan.
    `
  }
];

export const BimbelFaqData = [
  {
    title: "Bimbel online CoLearn tuh apa, sih?",
    desc: "Bimbel CoLearn adalah bimbel online terbaik yang bantu kamu cepet ngerti Matematika, Fisika, Kimia. Jadi kamu tetep punya waktu buat lakuin hal yang kamu suka."
  },
  {
    title: "Apa keuntungan ikut bimbel online CoLearn?",
    desc: "Ada banyak keuntungan yang bisa kamu dapetin: <ul><li>Tetep punya waktu buat jalanin hobi yang disuka dan dapet nilai matematika bagus. </li> <li>Belajarnya di kelas online, 1 jam tiap kelas. </li> <li>Belajar online dari rumah pakai aplikasi belajar CoLearn. </li> <li>Gurunya bisa bantu bikin pelajaran matematika, fisika, kimia cepet masuk otak.</li> <li>Ada garansi uang kembali 100% kalau nggak cocok.</li> </ul>"
  },
  {
    title: "Gimana cara daftar bimbel online CoLearn?",
    desc: "Daftarnya dari website atau aplikasi belajar CoLearn."
  },
  {
    title: "Harga bimbel online CoLearn berapa, Kak?",
    desc: "Harga bimbel online CoLearn 95.000 per bulan. Kamu juga bisa langganan sekaligus satu semester atau satu tahun, pastinya harganya jadi lebih murah."
  },
  {
    title: "Kalo cara bayarnya bisa pakai apa aja?",
    desc: "Banyak, tergantung CoFriends nyamannya pakai yang mana. Bisa Bank Transfer (BCA, BRI, Mandiri, dan BNI), e-wallet (OVO & GoPay), retail (Alfamart), dan kartu kredit (VISA & Mastercard). CoLearn juga menyediakan opsi pembayaran bulanan paket Bimbel mulai dari 95.000 per bulan."
  },
  {
    title: "Kita belajar mata pelajaran apa aja sih di Bimbel CoLearn?",
    desc: "Di bimbel online CoLearn, kamu bisa belajar Matematika, Fisika, dan Kimia. Ada Kurikulum 2013 dan Kurikulum Merdeka juga."
  },
  {
    title: "Berapa lama aku harus ikutan bimbel onlinenya?",
    desc: "Agar hasil maksimal, kurikulum di Bimbel CoLearn dirancang untuk kegiatan belajar selama 1 semester. Tapi kamu bisa cobain dulu 1 bulan. Kalau cocok, bisa lanjut bulanan atau lanjut sekaligus 1 semester atau lebih."
  },
  {
    title: "Siapa tutor online yang ngajar?",
    desc: "Mereka adalah tutor terbaik yang sudah lolos seleksi ketat dan berpengalaman mengajar online, lulusan terbaik dari universitas ternama, dan selalu kasih apresiasi dan motivasi."
  },
  {
    title: "Cara ngajar tutornya CoLearn gimana, sih? Aku kepo",
    desc: "Cara ngajarnya pake games seru, yang pasti bikin mood belajarmu jadi naik."
  }
];

export const FAQStructuredData: (data: FAQ[]) => Record<string, unknown> = (
  data = FAQData
) => {
  const FAQSchema = data.map((FAQ) => ({
    "@type": "Question",
    name: FAQ.title,
    acceptedAnswer: {
      "@type": "Answer",
      text: FAQ.desc
    }
  }));
  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: FAQSchema
  };
};
